import logo from './logo.svg';
import './App.css';

import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import { Analytics } from '@vercel/analytics/react';

import Landing from './containers/Landing';

function App() {
  return (
    <Router>
      <div className="App">
        <Analytics />
        <Routes>
          <Route path="/" element={<Landing />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
