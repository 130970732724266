import React from 'react';

// Mock data for the testimonials
const testimonials_dep = [
    {
        quote: "As a serial entrepreneur who built and sold a business in the weather industry, I appreciate how much insight is packed into The Forecast! If you are in this space, this is a must-read newsletter for you.",
        name: "Jordan Gale",
        title: "Serial Entrepreneur / Founder of WeatherPro",
        image: "path_to_jordan_image.jpg" // Path to the image of the person
    },
    {
        quote: "Love what Mia and the team are doing at The Forecast! I read it every week and they always unearth interesting weather trends and perspectives from real people building in the industry.",
        name: "Chris Frost",
        title: "CEO SkyWatch",
        image: "path_to_chris_image.jpg" // Path to the image of the person
    },
    {
        quote: "The Forecast has become an indispensable part of my morning routine. Their detailed analysis helps me plan my agricultural activities with greater confidence.",
        name: "Sarah Nguyen",
        title: "Agricultural Specialist",
        image: "path_to_sarah_image.jpg" // Path to the image of the person
    },
    {
        quote: "I rely on The Forecast for up-to-date, accurate weather predictions that help us manage our construction projects more effectively.",
        name: "Mark Belmont",
        title: "Project Manager",
        image: "path_to_mark_image.jpg" // Path to the image of the person
    },
    {
        quote: "Their insights into seasonal weather patterns are invaluable for our logistics team. Thanks to The Forecast, we're better prepared than ever.",
        name: "Lucy Hart",
        title: "Logistics Coordinator",
        image: "path_to_lucy_image.jpg" // Path to the image of the person
    }
];

// Mock data for the testimonials
const testimonials = [
   
];


const Testimonial = ({ testimonial }) => {
    return (
        <div style={{ backgroundColor: '#f2e912', borderColor: '#E5E7EB' }} className="flex w-full max-w-none flex-col space-y-4 rounded-lg p-8 sm:max-w-xs border">
            <figure className="flex flex-grow flex-col">
                <blockquote className="flex-grow text-gray-950">
                    <p style={{ color: '#222222' }} className="text-md font-medium font-open_sans text-left">
                        “{testimonial.quote}”
                    </p>
                </blockquote>
                <figcaption className="mt-8 flex gap-x-2 text-left">
                    <img className="h-10 w-10 rounded-full bg-gray-50" src={testimonial.image} alt={testimonial.name} />
                    <div className="flex flex-col">
                        <span style={{ color: '#222222' }} className="text-sm font-bold font-open_sans">{testimonial.name}</span>
                        <span style={{ color: '#222222' }} className="text-xs sm:text-sm font-light font-open_sans">{testimonial.title}</span>
                    </div>
                </figcaption>
            </figure>
        </div>
    );
}

const Testimonials = () => {
    return (
        <div className="mt-40 mb-12 flex flex-wrap gap-8 justify-center max-w-6xl">
            {testimonials.map((testimonial, index) => (
                <Testimonial key={index} testimonial={testimonial} />
            ))}
        </div>
    );
}

export default Testimonials;
