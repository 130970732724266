import React from 'react'

const CTA = () => {
    return (
        // <a href='https://www.weatherletter.com/' className='py-12 inline-flex justify-center items-center space-x-2 text-slate-500 font-serif'>
        //     <p>Let me read it first</p>
        //     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" data-slot="icon" class="mt-0.5 h-4 w-4 w-auto opacity-75 transition duration-200 group-hover:opacity-100"><path fill-rule="evenodd" d="M12.97 3.97a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 1 1-1.06-1.06l6.22-6.22H3a.75.75 0 0 1 0-1.5h16.19l-6.22-6.22a.75.75 0 0 1 0-1.06Z" clip-rule="evenodd">
        //     </path>
        //     </svg>
        // </a>
        <div className='py-12 inline-flex justify-center items-center space-x-2 text-slate-500 font-serif'>
            {/* <p>Let me read it first</p>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" data-slot="icon" class="mt-0.5 h-4 w-4 w-auto opacity-75 transition duration-200 group-hover:opacity-100"><path fill-rule="evenodd" d="M12.97 3.97a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 1 1-1.06-1.06l6.22-6.22H3a.75.75 0 0 1 0-1.5h16.19l-6.22-6.22a.75.75 0 0 1 0-1.06Z" clip-rule="evenodd">
            </path>
            </svg> */}
        </div>
    )
}

export default CTA