import React from 'react'
import NewsletterSignup from '../components/Landing/NewsletterSignup'
import CTA from '../components/Landing/CTA'
import Testimonials from '../components/Landing/Testimonials'

const Landing = () => {
    return (
        <div className=' bg-slate-900 flex flex-col items-center h-screen'>
            <div className='bg-[#f2e912] w-full'>
                <NewsletterSignup />
                <CTA />
            </div>
            <Testimonials />
        </div>
    )
}

export default Landing