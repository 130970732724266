import React, { useEffect, useState } from 'react';
import axios from 'axios'; // Import axios to make HTTP requests

function NewsletterSignup() {
  const [email, setEmail] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      // Define the API endpoint
      const apiUrl = `${process.env.REACT_APP_API_URL}/landing/signup`;

      // Send a POST request to the server with the email
      const response = await axios.post(apiUrl, {
        email: email
      });

      // Log the server response
      console.log('Server response:', response.data);

      alert('Thank you for subscribing!');

    } catch (error) {
      // Log any errors that occur during the request
      console.error('Signup failed:', error);
      alert('Signup failed, please try again later.');
    }
  };

  return (
    <div className=" flex flex-col justify-center items-center text-center px-4">
      <div className=" mt-6 py-6 ">
        <h2 className=' text-7xl py-4 rounded-lg shadow-xl text-center flex items-center'>⛅</h2>
      </div>
      <h1 className="text-3xl font-bold font-serif p-4 tracking-wider">A Weekly Weather Industry Newsletter 🌦️</h1>
      <p className="text-lg mb-6 px-6 font-serif">Join 1000+ weather enthusiasts who already enjoy the latest forecasts, climate insights, and weather tech innovations.</p>
      <form onSubmit={handleSubmit} className="flex justify-center items-center w-full max-w-xl h-14">
        <input
          type="email"
          placeholder="Join our growing community"
          className="text-black font-sans p-2 rounded-l-lg border-2 border-black border-r-0 w-3/5 h-full placeholder:text-slate-500 placeholder:text-lg"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <button type="submit" className="bg-black font-sans border-black hover:bg-gray-800 text-white font-semibold py-2 px-4 rounded-r-lg h-full text-lg">
          Subscribe For Free
        </button>
      </form>
      <p className="text-sm mt-6">Followed by meteorologists and weather fans alike</p>
    </div>
  );
}

export default NewsletterSignup;
